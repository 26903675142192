//企业管理
import { axios } from '@/utils/request'

//企业分页查询
export function enterprisePageList(parameter) {
  return axios({
    url: '/enterprise/page',
    method: 'post',
    params: parameter.page,
    data: parameter.queryParam,
  })
}

//企业详情
export function enterpriseDetail(parameter) {
  return axios({
    url: '/enterprise/detail',
    method: 'post',
    data: parameter,
  })
}

//新增企业
export function enterpriseAdd(parameter) {
  return axios({
    url: '/enterprise/add',
    method: 'post',
    data: parameter,
  })
}
//删除企业
export function enterpriseDelete(parameter) {
  return axios({
    url: '/enterprise/delete',
    method: 'post',
    data: parameter,
  })
}
//编辑企业
export function enterpriseEdit(parameter) {
  return axios({
    url: '/enterprise/edit',
    method: 'post',
    data: parameter,
  })
}

//供应商列表
export function storePageList(parameter) {
  return axios({
    url: '/store/page',
    method: 'post',
    params: parameter.page,
    data: parameter.queryParam,
  })
}

//供应商详情
export function storeDetail(parameter) {
  return axios({
    url: '/store/storeDetail',
    method: 'post',
    data: parameter,
  })
}

//新增供应商
export function storeAdd(parameter) {
  return axios({
    url: '/store/add',
    method: 'post',
    data: parameter,
  })
}
//删除供应商
export function storeDelete(parameter) {
  return axios({
    url: '/store/delete',
    method: 'post',
    data: parameter,
  })
}
//编辑供应商
export function storeEdit(parameter) {
  return axios({
    url: '/store/edit',
    method: 'post',
    data: parameter,
  })
}

//企业员工列表
export function customerPageList(parameter) {
  return axios({
    url: '/customer/page',
    method: 'post',
    params: parameter.page,
    data: parameter.queryParam,
  })
}
