<template>
  <div>
    <a-modal
      title="兑换卡发放"
      width="50%"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      @ok="handleSubmit"
      @cancel="handleCancel"
    >
      <a-spin :spinning="confirmLoading">
        <a-form :form="form">
          <a-row :gutter="24">
            <a-col :md="12" :sm="24">
              <a-form-item label="类型" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-select
                  v-decorator="['userType', { rules: [{ required: true, message: '请选择类型' }] }]"
                  placeholder="请选择"
                  allow-clear
                  @change="handleSelectChange"
                >
                  <a-select-option :value="1">普通用户</a-select-option>
                  <a-select-option :value="4">企业</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <a-form-item
                style="cursor: pointer"
                label="用户/企业"
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"
                has-feedback
              >
                <div
                  style="cursor: pointer; width: 100%; height: 100%; position: absolute; z-index: 9; left: 0; top: 0"
                  @click="chooseUser"
                ></div>
                <a-input
                  disabled
                  placeholder="请选择"
                  v-decorator="['userName', { rules: [{ required: true, message: '请选择用户/企业' }] }]"
                >
                  <span slot="suffix"> <a-icon type="right" /></span>
                </a-input>
                <a-input
                  style="visibility: hidden; position: absolute; top: 99999px"
                  placeholder="请选择"
                  v-decorator="['userId', { rules: [{ required: true, message: '请选择用户/企业' }] }]"
                />
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <a-form-item label="数量" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-input
                  type="number"
                  placeholder="请输入"
                  v-decorator="['number', { rules: [{ required: true, message: '请输入数量' }] }]"
                />
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <a-form-item label="面值" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-input
                  type="number"
                  placeholder="请输入"
                  v-decorator="['integral', { rules: [{ required: true, message: '请输入面值' }] }]"
                />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
    </a-modal>

    <choose-user ref="chooseUser" />
    <choose-enterprise ref="chooseEnterprise" />
  </div>
</template>

<script>
import chooseUser from './chooseUser.vue'
import chooseEnterprise from './chooseEnterprise.vue'
import { exchangeSendRecordAdd } from '@/api/modular/mallLiving/recharge'
export default {
  components: { chooseUser, chooseEnterprise },
  data() {
    return {
      loading: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      cos: null,
    }
  },
  watch: {},
  methods: {
    setUser(user) {
      console.log('user', user, this.form)

      this.form.setFieldsValue({ userName: user.name })
      this.form.setFieldsValue({ userId: user.id })
    },
    handleSelectChange(value) {
      console.log(value)
      this.form.setFieldsValue({
        userName: '',
      })
      this.form.setFieldsValue({
        userId: '',
      })
    },
    // 初始化方法
    add() {
      //下面是正常的初始化
      this.visible = true
    },
    chooseUser() {
      let userType = this.form.getFieldValue('userType')
      if (!userType) {
        return this.$message.info('请选择用户类型！')
      }
      if (+userType === 1) {
        this.$refs.chooseUser.init()
      }
      if (+userType === 4) {
        this.$refs.chooseEnterprise.init()
      }
    },
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
          exchangeSendRecordAdd(values).then((res) => {
            if (res.success) {
              this.$message.success('批量发放兑换卡成功！')
              this.$emit('ok', values)
              this.confirmLoading = false
              this.handleCancel()
            } else {
              setTimeout(() => {
                this.confirmLoading = false
              }, 600)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.form.resetFields() //重置表单
    },
  },
}
</script>
<style scoped>
/deep/ .ant-input-disabled {
  background: #fff;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
