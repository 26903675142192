//app端用户接口
import { axios } from '@/utils/request'
/*
 * @Description:
 * @Version:
 * @Author: 彭勃
 * @Date: 2022-02-25 15:45:50
 */

//app端用户分页查询
export function customerPageList(parameter) {
  return axios({
    url: '/customer/page',
    method: 'post',
    params: parameter.page,
    data: parameter.queryParam,
  })
}
//app端用户详情
export function customerDetail(parameter) {
  return axios({
    url: '/customer/detail',
    method: 'post',
    params: parameter,
  })
}

/**
 * 模块名:
 * 代码描述: 用户基数修改
 * 作者:陈莉莉
 * 创建时间:2022-08-18 19:50:12
 */
export function editCustomer(parameter) {
  return axios({
    url: '/customer/edit',
    method: 'POST',
    data: parameter,
  })
}

//用户添加金额
export function addBalance(parameter) {
  return axios({
    url: '/customer/addBalance',
    method: 'POST',
    data: parameter,
  })
}

//app端用户修改状态（启用 禁用 删除）
export function customerDelete(parameter) {
  return axios({
    url: '/customer/delete',
    method: 'post',
    data: parameter,
  })
}

//系统自带用户页面详情接口
export function sysUserDetail(parameter) {
  return axios({
    url: '/store/detail',
    method: 'post',
    params: parameter,
  })
}

//设置、取消官方账号
export function setUpOrCancelAccount(parameter) {
  return axios({
    url: '/customer/setUpOrCancelAccount',
    method: 'post',
    data: parameter,
  })
}
