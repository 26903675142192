import { render, staticRenderFns } from "./provideForm.vue?vue&type=template&id=71726dda&scoped=true"
import script from "./provideForm.vue?vue&type=script&lang=js"
export * from "./provideForm.vue?vue&type=script&lang=js"
import style0 from "./provideForm.vue?vue&type=style&index=0&id=71726dda&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71726dda",
  null
  
)

export default component.exports