//财务管理
import { axios } from '@/utils/request'

//企业充值积分记录_分页查询
export function rechargeIntegralRecordPage(parameter) {
  return axios({
    url: '/rechargeIntegralRecord/page',
    method: 'post',
    params: parameter.page,
    data: parameter.queryParam,
  })
}

//企业充值积分记录_查看详情
export function rechargeIntegralRecordDetail(parameter) {
  return axios({
    url: '/rechargeIntegralRecord/detail',
    method: 'post',
    data: parameter,
  })
}

//企业充值积分审核
export function rechargeIntegralRecordCheckResult(parameter) {
  return axios({
    url: '/rechargeIntegralRecord/checkResult',
    method: 'post',
    data: parameter,
  })
}

//企业充值积分批量审核
export function rechargeIntegralRecordCheckResultBatch(parameter) {
  return axios({
    url: '/rechargeIntegralRecord/checkResultBatch',
    method: 'post',
    data: parameter,
  })
}

//删除企业
export function enterpriseDelete(parameter) {
  return axios({
    url: '/enterprise/delete',
    method: 'post',
    data: parameter,
  })
}
//上传发票
export function uploadBill(parameter) {
  return axios({
    url: '/rechargeIntegralInvoice/uploadBill',
    method: 'post',
    data: parameter,
  })
}

//分页查询企业充值积分发票表
export function rechargeIntegralInvoicePage(parameter) {
  return axios({
    url: '/rechargeIntegralInvoice/page',
    method: 'post',
    params: parameter.page,
    data: parameter.queryParam,
  })
}

//分页查询积分兑换卡
export function exchangeIntegralInvoicePage(parameter) {
  return axios({
    url: '/exchangeIntegral/page',
    method: 'post',
    params: parameter.page,
    data: parameter.queryParam,
  })
}

//查看详情积分兑换卡
export function exchangeIntegralDetail(parameter) {
  return axios({
    url: '/exchangeIntegral/detail',
    method: 'post',
    data: parameter,
  })
}

//添加积分兑换卡
export function exchangeIntegralAdd(parameter) {
  return axios({
    url: '/exchangeIntegral/add',
    method: 'post',
    data: parameter,
  })
}

//激活兑换卡
export function exchangeIntegralLiveBatch(parameter) {
  return axios({
    url: '/exchangeIntegral/liveBatch',
    method: 'post',
    data: parameter,
  })
}

//分页查询积分兑换卡发放记录
export function exchangeSendRecordPage(parameter) {
  return axios({
    url: '/exchangeSendRecord/page',
    method: 'post',
    params: parameter.page,
    data: parameter.queryParam,
  })
}

//添加积分兑换卡发放记录
export function exchangeSendRecordAdd(parameter) {
  return axios({
    url: '/exchangeSendRecord/add',
    method: 'post',
    data: parameter,
  })
}

//分页查询积分记录表
export function integralRecordPage(parameter) {
  return axios({
    url: '/integralRecord/page',
    method: 'post',
    params: parameter.page,
    data: parameter.queryParam,
  })
}

//查看详情积分记录表
export function integralRecordDetail(parameter) {
  return axios({
    url: '/integralRecord/detail',
    method: 'post',
    data: parameter,
  })
}
